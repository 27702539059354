/*
a, img{
  -webkit-user-drag:none;
  -ms-user-drag:none;
  -moz-user-drag:none;
  user-drag:none;
}*/
.dndPlaceholder {
    background-color: #ddd;
    text-align:center;
    line-height:40px;
    font-weight:bold;
    min-height: 142px;
    display: block;
    position: relative;
}
.dndPlaceholder .no-contents{
  pointer-events:none;
  margin:auto;
  text-shadow: -1px -1px #add;
}
.dndDraggingSource {
    display: none;
}
.dndDragging {
    opacity: 0.7;
}
.dndDragover {
    border:2px dotted #fc0 !important;
    box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
}
.selected .box {
    border-color: #d6e9c6;
}
div[dnd-list]{
  min-height:50px;
}
.shake-updown {
  animation: shakeud 0.5s cubic-bezier(.36,.07,.49,.97) none;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  perspective: 1000px;
}
.shake-leftright {
  animation: shakelr 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}
.shake-icon {
  animation: shakeudsm 0.82s cubic-bezier(.36,.07,.19,.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shakelr {
  10%, 90% {
    transform: translate3d(-1px, 0, 0);
  }
  
  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes shakeud {
  10%, 90% {
    transform: translate3d(0, -1px, 0);
  }
  
  20%, 80% {
    transform: translate3d(0, 2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -3px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 3px, 0);
  }
}

@keyframes shakeudsm {
  10%, 90% {
    transform: translate3d(0, -0.5px, 0);
  }
  
  20%, 80% {
    transform: translate3d(0, 0.5px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -1px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 1px, 0);
  }
}
