
/*
.fillable-inline input[type="range"] {
  transform: rotateZ(270deg)
}
*/
.fillable-input .bottle-meter {
  height: 150px;

}
// Slider
  .input-range {
    -webkit-appearance: none;
    height: 200px;
    border-radius: 5px;
    background: #ccc;
    outline: none;
    writing-mode: bt-lr; /* IE */
    -webkit-appearance: slider-vertical; /* WebKit */

}

// Firefox Overrides
::-moz-range-track {
    background: #ccc;
    border: 0;
}

input::-moz-focus-inner { 
  border: 0; 
}
.fillable-mask-solid {
  mask-image: image('../images/nalgene.png')
}

.fillable-mask-soft {
  mask-image: image('../images/bottle_water.jpg')
}

.fillable-mask-bladder {
  mask-image: image('../images/bottle_water_bladder.jpg')
}

.fillable-bladder {
  background-blend-mode: multiply;
  background: url('../images/bottle_water_bladder.jpg')
  
}
.fillable-soft {
  background-blend-mode: multiply;
  background: url('../images/bottle_water.jpg') 
}
.fillable-solid {
  background-blend-mode: multiply;
  background: url('../images/nalgene.png')
  
}
