@import './itemElement.scss';/**/
@import './accountBadge.scss';
@import './drag-drop.scss';
@import './item.scss';/**/
@import './progress.scss';
@import './common.scss';
@import './panel.scss';
@import './fillable.scss';
@import './intro.scss';
@import './trip.scss';


#root {
  overscroll-behavior: none;
  overflow-y: scroll;
  width: 100%;
  height: 100%;
  position: absolute;
}
/* workaround for bootstrap 4 modals not working */
.fade.in {
    opacity: 1;
  }
  
  .modal.in .modal-dialog {
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  
  .modal-backdrop.in {
    opacity: 0.5;
  }
.custom-select {
    text-transform: capitalize;
}
button {
    text-transform: capitalize;
}
/*
.item-element{
    background-color: #3B1;
}
.account-edit{
    background-color: #335533;
}
.account-select{
    background-color: #FF0033;
}
.account-create{
    background-color: #3355AA;
}*/
.blank-image {
    background-image: url('../images/help.png');
    height:200px;
    width:100%;
    background-repeat: no-repeat;
    background-position: center center;

}
/* stupid hack for react bootstrap*/
.dropdown.open > .dropdown-menu{
  display: block;
}