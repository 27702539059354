/* account styles */
.account-badge {
    border-radius:50%;
    overflow:hidden;
    height: 64px;
    width: 64px;
    border:2px solid;
    color:#333;
  /*account-badge{
    z-index:5;
  }*/
  img {
    margin: 50% 0 0 50%;
    transform: translate3d(-50%, -50%, 0);
  }
  i.fa{
    height:inherit;
    width:inherit;
    margin:5% 0 0 5%;
  }
  &.online{
    border-color:#5cb85c;
  }
  &.disconnected{
    border-color:#f0ad4e;
  }
  &.offline{
    border-color:#ff0000;
  }
  &.connecting{
    border-color:#5bc0de;
  }
  nav{
    background-color:#3fe;
    position:absolute;
    right:0;
  }
  .navbar-collapse{
    overflow:hidden;
  }
}
