
.progress-box {
	width: 100%;
	margin: 20px 0;
}

.progress-box .percentage-cur .num {
	margin-right: 5px;
}

.progress-box .progress-bar {
	width: 100%;
	height: 12px;
	background: #f2f1f1;
	margin-bottom: 3px;
	border: 1px solid #dfdfdf;
	box-shadow: 0 0 2px #D5D4D4 inset;
  position: relative;
}

.progress-box .progress-bar .inner {
  position: relative;
	width: 0;
	height:100%;
	background: #239bd6; 
}

.progress-bar .inner {
	height: 0;
	width: 0;
	transition: all 1s ease-out;
}

.progress-bar-slider .inner {
  transition: none;
}

.progress-bar-slider .inner:after {
  content: " ";
  width: 5px;
  height: 14px;;
  background-color:#ccc;
  position: absolute;
  right: -2px;
  top: -2px;
  border: 1px solid #bbb;
  border-radius: 2px;
  box-shadow: 0px 0px 2px rgba(0,0,0,0.3);
  margin: 0px;
}

.progress-slider {
  opacity: 0;
  width: 100%;
  height: 15px;
  position: absolute;
  top: 0px;
  left: 0px;
  cursor: pointer;
  z-index: 1;
}
.progress{
  margin-top:5px;
  margin-left:15px;
  margin-right:15px;
}

.loader{
  text-align: center;
  padding: 1rem;
}
.panel .loading .panel-body{
  background-color: #D5D4D4;
}
/*
  Set the color of the icon
*/
.loader svg path,
.loader svg rect{
  fill: #FF6700;
}
