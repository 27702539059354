/* css for pkItem module */
.inventory-tray {
  overflow-x: scroll;
  height: 15vh;
  & > .tray {
    display: inline-flex;
  }
}
.item-element .badge-group{
  background-color: rgba(200,200,200,0.7);
  border-radius: 0 4px;
  position:absolute;
}
/* item icon component */
.item-icon {
  width: 20vw;
}
.item-icon span.thumbnail{
  width:50px;
  height:50px;
}
.item-icon span img,
.item-element img{
  /* bootstrap tries to assign auto*/
  flex-grow: 1;
}

/* item element component */
.item-element div.thumbnail{
  width:70px;
  height:70px;
}
.item-element i{
  padding:0 5px;
  pointer-events: none;
}

.item-element{
  margin:2px;
}

.item-element.thumbnail {
  width:auto;
  margin-bottom:0;
  /*
  height:100%;
  max-height:100px;
  max-width:100px;
  */
}
.thumbnail.shadow-light .badge-group{
  margin-top:-20px;
}
.thumbnail .center-block{
  margin:15px 0;
  text-align:center;
}
.shadow-light {
  box-shadow: 5px 5px 5px -1px rgba(130, 130, 130, 0.4);
  -webkit-box-shadow: 5px 5px 5px -1px rgba(130, 130, 130, 0.4);
}
.thumbnail input[type=file]{
  display:none;
}
/* item static component */
.item-static img{
  height:60px;
  width:60px;
}
/* item profile component */
.paginator-main.ng-leave .item-profile{
  -webkit-animation:ipOut 2500ms;
  animation:ipOut 2500ms;
}
.paginator-main.ng-enter .item-profile{
  
  animation:ipIn 0.82s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes ipIn{
  10%, 90% {
    transform: translate3d(0, -1px, 0);
  }
  
  20%, 80% {
    transform: translate3d(0, 2px, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(0, -4px, 0);
  }

  40%, 60% {
    transform: translate3d(0, 4px, 0);
  }
}
@keyframes ipOut{
	to {  
    bottom:-100%;
	}
}
.item-profile{
  width:95%;
  color:#fff;
  position:fixed !important;
  padding:3px 0 5px 0;
}
.item-profile h4,h3,h2{
  margin:10px 0;
}
.item-profile .profile-icon{
  border-radius:50%;
  overflow:hidden;
  border:2px solid;
  width:50px;
  height:50px;
  margin:2px 0 0 0;
}

.item-profile .profile-icon img{
  min-width:50px;
  min-height:50px;
}
.item-profile .parent-icon{
  border-radius:50%;
  overflow:hidden;
  border:2px solid;
  width:40px;
  height:40px;
  margin:8px 0 0 0;
}
.item-profile .parent-icon img{
  min-width:45px;
  min-height:45px;
}

.item-profile .profile-meta{
  /*text-align:center;*/
  color:#fff;
}
