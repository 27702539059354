// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=20y0u0kleqtbzEKgVuIpcmGtdhZ */

// Feel free to copy&paste color codes to your application */


// As hex codes */

$color-primary-0: #D38B47;	// Main Primary color */
$color-primary-1: #FFD0A3;
$color-primary-2: #F5B373;
$color-primary-3: #B56C26;
$color-primary-4: #8F4C0D;

$color-complement-0: #2E7582;	// Main Complement color */
$color-complement-1: #79B0BB;
$color-complement-2: #498A97;
$color-complement-3: #1A626F;
$color-complement-4: #0A4C58;



// As RGBa codes */

$rgba-primary-0: rgba(211,139, 71,1);	// Main Primary color */
$rgba-primary-1: rgba(255,208,163,1);
$rgba-primary-2: rgba(245,179,115,1);
$rgba-primary-3: rgba(181,108, 38,1);
$rgba-primary-4: rgba(143, 76, 13,1);

$rgba-complement-0: rgba( 46,117,130,1);	// Main Complement color */
$rgba-complement-1: rgba(121,176,187,1);
$rgba-complement-2: rgba( 73,138,151,1);
$rgba-complement-3: rgba( 26, 98,111,1);
$rgba-complement-4: rgba( 10, 76, 88,1);



// Generated by Paletton.com © 2002-2014 */
// http://paletton.com */
