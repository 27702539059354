@import './styles.scss';

.widget-body {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.64);
  background-color: $color-primary-0;
  border-radius: 8px;
}
/* this might clobber a bootstrap panel */
.panel-body{
  border-radius: 4px;
  min-height: 100px;
  clear: both;
  
}
.panel-flex .panel-body{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
 }

.panel {
  width: 100%;
  color: $color-primary-0;
  margin-bottom: 24px;
  border-radius: 4px;
  &.panel-fill > .panel-body{
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.64);
  background: rgba(225, 225, 225, 0.88)
}
  /*
  @media only screen and (max-width: 767px){
    margin-bottom: 48px;
  }

  &:first-of-type{
    margin-top: 44px;
    @media only screen and (max-width: 767px){
      margin-top: 45px;
    }
  }*/
}
.panel-header-tab {
  margin-bottom: 12px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  color: $color-primary-2;
}
.panel .panel-header{
  color: $color-primary-2;
  padding-bottom: 24px;
}
.label-right,.tab-right,.footer-right{
  display: inline-flex;
  flex-direction: row-reverse;
}
.label-left,.tab-left,.footer-left{
  display: inline-flex;
}
.panel .panel-foot {
  margin-top: 16px;
  color: $color-complement-1;
}
