/*.intro {
  height: 319px;
  width: 150px;
  background-image:url('/images/spacemonkey.png');
  float:left;
}*/
.picture-container {
  background-color: rgba(242,34,122,0.65);
}
video#bgvideo {
filter: grayscale(100%) contrast(1);
mix-blend-mode: multiply;
position: fixed;
top: 50%;
left: 50%;
min-width: 100%;
min-height: 100%;
width: auto;
height: auto;
z-index: -100;
-ms-transform: translateX(-50%) translateY(-50%);
-moz-transform: translateX(-50%) translateY(-50%);
-webkit-transform: translateX(-50%) translateY(-50%);
transform: translateX(-50%) translateY(-50%);
background: url('/images/0722161814a.jpg') no-repeat;
background-size: cover;
}
.picture-container::after{
  background-color: #23278a;
  mix-blend-mode: lighten;
}

.intro {
    background-image: url('../images/super-bunny.png');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    background-blend-mode: luminosity;
}