/* css for pkItem module */
.item-create .panel{
    background-color: azure;
    background-image: url('../images/super-bunny.png');
    background-repeat: no-repeat;
    background-position-y: bottom;
    background-position-x: right;
    background-blend-mode: luminosity;
}
.item-edit {
  /* height: 90vh; */
  & .react-card-flip {
    height:85vh;
  }
}