/* css for pkItem module */
item-create > div{
  position: fixed;
}
.trip-create .panel {
  /* height: 90vh; */
  background-color: azure;
  background-image: url('../images/super-bunny.png');
  background-repeat: no-repeat;
  background-position-y: bottom;
  background-position-x: right;
  background-blend-mode: luminosity;
}