

$primaryColor:   #0277bd;
$accentColor:    #1de9b6;
$bgColor:        #efefef;
$darkBgColor:    #263238;
$lightBgColor:   #37474f;
$lightTextColor: #f5f5f5;
$darkTextColor:  #212121;

/*
body {
   height: 100%;
   // browsers default to 16px for font-size,
   // so setting top-level font-size to 62.5%
   // scales 1rem to equal 10px.
   font: 62.5% 'Roboto', sans-serif;
}

.thumbnail {
  width:auto;*/
  /*height:100%;
  
  max-height:100px;
  max-width:100px;
 
} */
.scrollable{
 /* position:fixed;*/
  height:100%;
  width:inherit;
  overflow-y:auto;
}

.left-top, .left-bottom{
  position:absolute;
  left:10px;
  }
.left-bottom{
  bottom:10px;
  z-index:10;
}
.left-top{
  top:10px;
  z-index:2;
}
.right-top, .right-bottom{
  position:absolute;
  right:10px;
}
.right-top{
  top:10px;
  z-index:4;
}
.right-bottom{
  bottom:10px;
  z-index:6;
}
.danger{
 background-color:#d9534f;
 color:#fff;
 }
.success{
 background-color:#5cb85c;
 color:#fff;
 }
.warning{
 background-color:#f0ad4e;
 color:#fff;
 }
.shadow-light {
    box-shadow: 5px 5px 5px -1px rgba(130, 130, 130, 0.4);
}/*
.input-group-addon{
  padding:6px 8px;
}

.form-control{
  height:auto;
  padding:4px;
}*/
.headline{ color:#ffc;
 text-shadow:#333 1px 1px;
}
.detail {
  width:auto;
  height:100%;
  max-height:200px;
  max-width:200px;
}
/*
.thumbnail{
  margin-bottom:0;
}
.thumbnail input[type=file]{
  display:none;
}
.button-group{
  padding-bottom:20px;
}
.navbar-brand{
  padding-top:0;
}

.grid{
  padding-left:10px;
}
.grid > li{
  overflow:auto;
}
.panel-height{
  height:140px;
}
.list-group-item{
  overflow:auto;
}
.thumbnail.shadow-light .badge-group{
  margin-top:-20px;
}
.thumbnail .center-block{
  margin:15px 0;
  text-align:center;
}*/
